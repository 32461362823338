import { withTracking } from 'tracking/hocs/WithTracking'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { ButtonClickEvent } from 'tracking/events/ButtonClickEvent'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { NavigationClickEvent } from 'tracking/events'
import ListItem, { ListItemProps } from '@material-ui/core/ListItem/ListItem'

interface ITrackingProps {
  label: string
  value?: string
}

type ButtonWithTrackingProps = ButtonTrackingProps | NavLinkTrackingProps
type NavLinkTrackingProps = NavLinkProps & ITrackingProps & { component?: any }
type ButtonTrackingProps = ButtonProps & ITrackingProps & { component?: any }
type ListItemTrackingProps =
  | (ListItemProps & ITrackingProps & { component?: any })
  | NavLinkTrackingProps

function isNavlink(
  props: ButtonWithTrackingProps
): props is NavLinkTrackingProps {
  return !!(props as NavLinkProps).to
}

function trackerMapper(props: ButtonWithTrackingProps) {
  if (isNavlink(props)) {
    return navLinkMapper(props)
  } else {
    if (props.href) {
      return new NavigationClickEvent(props.label, props.href)
    }
    return new ButtonClickEvent(props.label, props.value)
  }
}

function navLinkMapper(props: NavLinkTrackingProps) {
  let href: string
  if (typeof props.to === 'string') {
    href = props.to
  } else {
    href = props.to.pathname! + props.to.search!
  }
  return new NavigationClickEvent(props.label, href)
}

function listItemMapper(props: ListItemTrackingProps) {
  if (isNavlink(props)) {
    return navLinkMapper(props)
  } else {
    if (props.href) {
      return new NavigationClickEvent(props.label, props.href)
    }
    return new ButtonClickEvent(props.label, props.value)
  }
}

export const ButtonWithTracking = withTracking(Button, 'onClick', trackerMapper)
export const IconButtonWithTracking = withTracking(
  IconButton,
  'onClick',
  trackerMapper
)

export const NavLinkWithTracking = withTracking(
  NavLink,
  'onClick',
  navLinkMapper
)

export const ListItemWithTracking = withTracking(
  ListItem,
  'onClick',
  listItemMapper
)
