export interface SetActionPayload<T> {
  key: string
  data: T
}

export interface UpdateActionPayload<T> {
  key: string
  data: Partial<T>
}

export interface GetActionPayload {
  key: string
}

export interface MergeActionPayload<T> {
  data: { [key: string]: Partial<T> }
}

export interface RemoveActionPayload {
  key: string
}

export const getActionName = (storeName: string): string => `${storeName}/get`

export const mergeActionName = (storeName: string): string =>
  `${storeName}/merge`

export const setActionName = (storeName: string): string => `${storeName}/set`

export const removeActionName = (storeName: string): string =>
  `${storeName}/remove`

export const updateActionName = (storeName: string): string =>
  `${storeName}/update`
