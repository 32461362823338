import { DataStore } from 'common/utils/store/DataStore'
import { ADDRESS_STORE_NAME } from 'Address/constants/store'
import { Record } from 'immutable'
import { AddressType } from 'Address/types/address'
import { customAddressActions } from 'Address/redux/customAddressActions'

const AddressRecordFactory = Record<AddressType>({
  hash: '0x0',
  balance: '0',
  isSmartContract: false
})

export const addressStore = new DataStore<
  AddressType,
  typeof customAddressActions
>(ADDRESS_STORE_NAME, AddressRecordFactory, {
  actionCreators: customAddressActions
})

export const addressReducer = addressStore.reducer
export const addressFactory = addressStore.RecordFactory

export const addressActions = addressStore.actions
export const addressSelectors = addressStore.selectors

export default addressStore
