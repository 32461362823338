import * as React from 'react'
import { ISearchResult } from 'App/types/search'
import { AddressRecord } from 'Address/types/address'
import { BlockRecord } from 'Blocks/types/blocks'
import { TransactionRecord } from 'Transactions/types/transactions'
import Icon from '@material-ui/core/Icon/Icon'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './SearchResult.styles'
import { Routes } from 'App/constants/Routes'
import { ListItemWithTracking } from 'tracking/components'
import { NavLink } from 'react-router-dom'
import { toBN, fromWei } from 'web3-utils'
import TableCell from 'Address/components/Address/Address'
import { numberStringWithComma } from 'App/utils/numberStringWithComma'
import { FormattedMessage } from 'react-intl'

interface ISearchResultProps extends WithStyles {
  result: ISearchResult
  onClick(): any
}

class SearchResult extends React.PureComponent<ISearchResultProps> {
  static routeMapper = {
    address: (data: AddressRecord) =>
      Routes.AddressInfo.getPath({ id: data.hash }),
    block: (data: BlockRecord) => Routes.Block.getPath({ id: data.number }),
    transaction: (data: TransactionRecord) =>
      Routes.Transaction.getPath({ id: data.hash })
  }
  static renderAddress = (data: AddressRecord, className: string) => {
    return (
      <React.Fragment>
        <Icon className={className}>
          <FormattedMessage id="App.components.SearchResult.assessment" />
        </Icon>
        <div>
          <div>
            <FormattedMessage id="App.components.SearchResult.balance" />
            { fromWei(toBN(data.balance).toString()) }{' '}TT
          </div>
        </div>
      </React.Fragment>
    )
  }

  static renderBlock = (data: BlockRecord, className: string) => {
    return (
      <React.Fragment>
        <Icon className={className}>
          <FormattedMessage id="App.components.SearchResult.cropSquare" />
        </Icon>
        <div>
          <div>
            <FormattedMessage id="App.components.SearchResult.blockHeight" />
            {data.number}
          </div>
          <div>
            <FormattedMessage id="App.components.SearchResult.transactions" />
            {data.transactionCount}
          </div>
        </div>
      </React.Fragment>
    )
  }
  static renderTransaction = (data: TransactionRecord, className: string) => {
    return (
      <React.Fragment>
        <Icon className={className}>
          <FormattedMessage id="App.components.SearchResult.payment" />
        </Icon>
        <div>
          <div>
            <FormattedMessage id="App.components.SearchResult.blockHeight" />
            {data.blockNumber}
          </div>
          <div>
            <FormattedMessage id="App.components.SearchResult.gasUsed" />
            {numberStringWithComma(String(data.gasUsed))} wei
          </div>
        </div>
      </React.Fragment>
    )
  }

  static renderType = {
    address: SearchResult.renderAddress,
    block: SearchResult.renderBlock,
    transaction: SearchResult.renderTransaction
  }

  render() {
    const {
      classes,
      onClick,
      result: { type, data }
    } = this.props

    // @ts-ignore
    const searchResult = SearchResult.renderType[type](data, classes.icon)
    return (
      <ListItemWithTracking
        button={true}
        label="Result"
        onClick={onClick}
        component={NavLink}
        // @ts-ignore
        to={SearchResult.routeMapper[type](data)}
      >
        {searchResult}
      </ListItemWithTracking>
    )
  }
}

export const Unconnected = SearchResult
export default withStyles(styles)(SearchResult)
