import { IActionCreator, ICreateAction } from 'common/types/IActionCreator'
import { IAction } from 'common/types/IAction'

export class ActionCreator<T = any> implements IActionCreator<T> {
  type: string
  create: ICreateAction<T>

  constructor(type: string) {
    this.type = type
    this.create = this.buildActionCreator(type)
  }

  buildActionCreator = (type: string) => (payload: T): IAction<T> => {
    return {
      type: type,
      payload
    }
  }
}
