const parseEnv = (name: string): string => {
  const env = window['_env_'][name]

  if (!env) {
    throw new Error(`Missing environment variable for ${name}`)
  }

  return env
}

const config =
  process.env.NODE_ENV === 'production'
    ? {
        SOCKET_URL: '/',
        DATA_STORE_URL: '/api/v1/data',
        RPC_URL: parseEnv('RPC_URL'),
        TOKEN_MANAGER: parseEnv('TOKENMANAGER_URL'),
      }
    : {
        SOCKET_URL: '/',
        DATA_STORE_URL: 'http://localhost:8220/api/v1/data',
        RPC_URL: parseEnv('RPC_URL'),
        TOKEN_MANAGER: parseEnv('TOKENMANAGER_URL'),
      }
export default config
