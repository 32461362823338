import { blockActions } from 'Blocks/redux/blockStore'
import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { appUrls } from 'App/constants/urls'
import { EMPTY } from 'rxjs'
import { BlockSchema } from 'Blocks/schemas/blockSchema'
import { IBlockGetAction } from 'Blocks/types/blocks'
import { IAction } from 'common/types/IAction'
import { normalize } from 'normalizr'
import { IGlobalState } from 'configuration/reducers'

export const fetchBlock: Epic<IAction> = (action$, state$) =>
  action$.pipe(
    ofType<IAction, IBlockGetAction>(blockActions.get.type),
    withLatestFrom(state$),
    switchMap(
      ([{ payload, observable }, state]: [IBlockGetAction, IGlobalState]) => {
        const block = state.blocks.get(payload.key)
        if (block) {
          observable.next(block)
          return EMPTY
        }

        return ajax({
          url: appUrls.TOKEN_MANAGER,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            'jsonrpc': '2.0',
            'id': 1,
            'method': 'tokenManager.Block',
            'params': [Number(payload.key)],
          }
        }).pipe(
          map(({ response }) => normalize(response.result[0], BlockSchema)),
          tap(({ entities: { Blocks }, result }) => {
            observable.next(Blocks[result])
          }),
          map(({ entities: { Blocks } }) =>
            blockActions.merge.create({
              data: Blocks
            })
          ),
          catchError(error => {
            observable.error(error.message)
            return EMPTY
          })
        )
      }
    )
  )
