import { ADDRESS_STORE_NAME } from 'Address/constants/store'
import { ObservableActionCreator } from 'common/utils/actions/ObservableActionCreator'
import {
  IAddressQueryTransactionAction,
  IAddressQueryTransactionPayload
} from 'Address/types/address'

export const customAddressActions = {
  queryTransactions: new ObservableActionCreator<
    IAddressQueryTransactionPayload,
    IAddressQueryTransactionAction
  >(`${ADDRESS_STORE_NAME}/queryTransactions`)
}
