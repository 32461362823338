import * as React from 'react'
import * as ReactDom from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from 'configuration/configureStore'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import App from 'App/components/App/App'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import theme from 'configuration/theme'
import IntlContainer from './i18n/localeProvider'

// To keep reducers self-sufficient and reusable, we choose to not set
// initial state here, and let each reducer to handle the default state
// https://github.com/reactjs/redux/issues/1189#issuecomment-168025590
const initialState = {}

// Configure store
const store = configureStore(initialState)

// Create render function
const render = (Component: any) => {
  ReactDom.render(
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer>
        <Provider store={store}>
          <IntlContainer>
            <BrowserRouter>
              <Component />
            </BrowserRouter>
          </IntlContainer>
        </Provider>
      </AppContainer>
    </MuiThemeProvider>,
    document.getElementById('root')
  )
}

// First time render
render(App)

// Hot Reload Module API
if (module.hot) {
  module.hot.accept('App/components/App/App', () => {
    const NextApp = require('App/components/App/App').default
    render(NextApp)
  })
}
