import { Route } from 'App/utils/Route'

export const Routes = {
  Home: new Route({
    path: '/',
    name: 'HOME',
    exact: true,
    showSearch: false
  }),
  Transactions: new Route({
    path: '/transactions',
    name: 'TRANSACTIONS',
    exact: true,
    showSearch: true
  }),
  Transaction: new Route({
    path: '/transactions/:id',
    name: 'TRANSACTION INFO',
    exact: true,
    showSearch: true
  }),
  Blocks: new Route({
    path: '/blocks',
    name: 'BLOCKS',
    exact: true,
    showSearch: true
  }),
  Block: new Route({
    path: '/blocks/:id',
    name: 'BLOCK INFO',
    exact: true,
    showSearch: true
  }),
  AddressInfo: new Route({
    path: '/address/:id',
    name: 'ADDRESS INFO',
    exact: true,
    showSearch: true
  }),
  NotFoundPage: new Route({
    path: '',
    name: 'NOT FOUND',
    exact: false,
    showSearch: true
  })
}
