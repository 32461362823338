export function flatten(obj: {}, header: string = '') {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    if (typeof value === 'object') {
      acc = { ...flatten(obj[key], `${header}${key}.`), ...acc }
    } else {
      acc[header + key] = value
    }
    return acc
  }, {})
}
