import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { appUrls } from 'App/constants/urls'
import { IAction } from 'common/types/IAction'
import { IAddressGetAction } from 'Address/types/address'
import { addressActions } from 'Address/redux/addressStore'
import { normalize } from 'normalizr'
import { AddressSchema } from 'Address/schemas/addressSchema'
import { concat, EMPTY, forkJoin } from 'rxjs'
import { isAddress } from 'web3-utils'

export const fetchAddress: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, IAddressGetAction>(addressActions.get.type),
    switchMap(({ payload, observable }) => {
      if (!isAddress(payload.key) || payload.key.length !== 42) {
          observable.error("invalid address")
          return EMPTY
      }
      return concat(
          forkJoin([
            ajax({
              url: appUrls.RPC_URL,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: {
                'jsonrpc': '2.0',
                'id': 1,
                'method': 'eth_getBalance',
                'params': [payload.key, 'latest'],
              }
            }),
            ajax({
              url: appUrls.TOKEN_MANAGER,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: {
                'jsonrpc': '2.0',
                'id': 1,
                'method': 'tokenManager.IsSmartContract',
                'params': [payload.key],
              }
            }),
          ]).pipe(
            map(([balance, isSmartContract]) => {
              return normalize({
                hash: payload.key.toLowerCase(),
                balance: balance.response.result,
                isSmartContract: isSmartContract.response.result
              }, AddressSchema)
            }),
            tap(({ entities: { Address }, result }) => {
              observable.next(Address[result])
            }),
            map(({ entities: { Address }, result }) =>
              addressActions.set.create({
                data: Address[result.toLowerCase()],
                key: result.toLowerCase()
              }),
            ),
            catchError(error => {
              observable.error(error.message)
              return EMPTY
            })
          )
        )
      })
  )
