import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'
import { margin, padding } from 'common/utils/styles'

export default ({ spacing }: Theme) =>
  createStyles({
    container: {
      padding: padding(spacing.unit * 4, 0),
      textAlign: 'center'
    },
    title: {
      marginBottom: spacing.unit * 2
    },
    image: {
      margin: margin(spacing.unit * 4, 0),
      width: '100%',
      maxWidth: 700
    }
  })
