import createStyles from '@material-ui/core/styles/createStyles'

export default () =>
  createStyles({
    full: {
      position: 'fixed',
      zIndex: 200,
      backgroundColor: '#4F4F4F',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    icon: {
      color: 'white',
      marginRight: 8,
      marginTop: 8,
    },
    list: {
      color: 'white',
      margin: 'auto',
    },
    item: {
      textAlign: 'center',
      display: 'block',
    },
    languageContainer: {
      color: '#fff',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: 15,
    },
  })
