export default function getLocale(): string {
  const locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en'

  return languageLocaleSwitcher(locale)
}

export const languageLocaleSwitcher = (locale: string) => {
  switch (locale.toLowerCase()) {
    case 'zh-tw':
    case 'zh-hk':
    case 'zh-sg':
    case 'zh-cht':
    case 'zh-hant':
      return 'zh-Hant'
    case 'zh-cn':
    case 'zh-chs':
    case 'zh-hans':
      return 'zh-Hans'
    default:
      return locale.split(/[_-]+/)[0]
  }
}

export const languageLocaleForMomentSwitcher = (locale: string) => {
  switch (locale) {
    case 'zh-Hant':
      return 'zh-tw'
    case 'zh-Hans':
      return 'zh-cn'
    default:
      return 'en'
  }
}
