import { DataStore } from 'common/utils/store/DataStore'
import { TRANSACTION_STORE_NAME } from 'Transactions/constants/store'
import { Record } from 'immutable'
import {
  ITransactionQueryAction,
  ITransactionQueryPayload,
  TransactionType
} from 'Transactions/types/transactions'
import { ObservableActionCreator } from 'common/utils/actions/ObservableActionCreator'

const TransactionRecordFactory = Record<TransactionType>({
  tid: 0,
  status: 1,
  gasUsed: 0,
  gasLimit: 0,
  gasPrice: '',
  from: '',
  blockNumber: 0,
  timestamp: '',
  inputData: '',
  to: '',
  value: '',
  hash: '0x0',
  transactionIndex: 0,
  nonce: 0
})

const customActions = {
  query: new ObservableActionCreator<
    ITransactionQueryPayload,
    ITransactionQueryAction
  >(`${TRANSACTION_STORE_NAME}/query`)
}

const transactionStore = new DataStore<TransactionType, typeof customActions>(
  TRANSACTION_STORE_NAME,
  TransactionRecordFactory,
  { actionCreators: customActions }
)

export const transactionReducer = transactionStore.reducer
export const transactionFactory = transactionStore.RecordFactory

export const transactionActions = transactionStore.actions
export const transactionSelectors = transactionStore.selectors
