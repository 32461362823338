import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({ spacing }: Theme) =>
  createStyles({
    results: {
      position: 'absolute',
      zIndex: 9000,
      width: '100%',
      left: 0,
      top: 'calc(100% + 4px)'
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: spacing.unit
    },
    list: {
      padding: 0
    }
  })
