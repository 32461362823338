import {
  buildCrudActions,
  ICrudActionCreators
} from 'common/utils/actions/buildCrudActions'
import { IActionCreators } from 'common/types/IActionCreator'
import { Map, Record, RecordOf } from 'immutable'
import Factory = Record.Factory
import { buildCrudDataReducers } from 'common/utils/reducers/buildCrudDataReducers'
import { IReducers } from 'common/types/IReducer'
import { createSelectors } from 'common/utils/selectors/createDataSelectors'
import { IDataSelectors } from 'common/types/IDataSelectors'
import { Reducer } from 'redux'
import { IDataState } from 'common/types/IDataState'
import { IAction } from 'common/types/IAction'

export class DataStore<
  T,
  A extends IActionCreators = {},
  R extends IReducers<T> = {}
> {
  RecordFactory: Factory<T>
  storeName: string
  actions: ICrudActionCreators<T> & A
  reducer: Reducer<IDataState<T>, IAction>
  selectors: IDataSelectors<RecordOf<T>>

  constructor(
    storeName: string,
    RecordFactory: Factory<T>,
    options?: {
      initialState?: { [key: string]: T }
      reducerOverrides?: R
      actionCreators?: A
    }
  ) {
    this.storeName = storeName
    this.RecordFactory = RecordFactory

    this.actions = buildCrudActions<T, A>(
      storeName,
      options && options.actionCreators
    )

    this.reducer = buildCrudDataReducers<T, R>(
      Map(this.getInitialState(options && options.initialState)),
      storeName,
      this.RecordFactory,
      options && options.reducerOverrides
    )

    this.selectors = createSelectors<RecordOf<T>>()
  }

  private getInitialState = (initialState?: { [key: string]: T }) => {
    if (!initialState) return {}
    const mappedValues: { [key: string]: RecordOf<T> } = {}
    for (let key in initialState) {
      mappedValues[key] = this.RecordFactory(initialState[key])
    }
    return mappedValues
  }
}
