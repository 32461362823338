import { blockActions } from 'Blocks/redux/blockStore'
import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { appUrls } from 'App/constants/urls'
import { EMPTY } from 'rxjs'
import { BlockSchema } from 'Blocks/schemas/blockSchema'
import { List } from 'immutable'
import { BlockRecord, IBlockQueryAction } from 'Blocks/types/blocks'
import { IAction } from 'common/types/IAction'
import { normalize } from 'normalizr'

export const queryBlocks: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, IBlockQueryAction>(blockActions.query.type),
    switchMap(({ payload, observable }) =>
      ajax({
        url: appUrls.TOKEN_MANAGER,
        method: 'POST',
        headers : {
          'Content-Type': 'application/json',
        },
        body: {
          'jsonrpc': '2.0',
          'id': 1,
          'method': 'tokenManager.Blocks',
          'params': [payload],
        },
      }).pipe(
        map(({ response: { result } }) => ({
          normalized: normalize(result, [BlockSchema])
        })),
        tap(({ normalized: { entities: { Blocks }, result } }) => {
          const records: BlockRecord[] = result.map((id: number) => Blocks[id])
          observable.next({
            blocks: List(records),
          })
          observable.complete()
        }),
        map(({ normalized: { entities: { Blocks } } }) =>
          blockActions.merge.create({
            data: Blocks
          })
        ),
        catchError(error => {
          observable.error(error.message)
          return EMPTY
        })
      )
    )
  )
