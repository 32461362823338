type pad = number | string

const process = (p: pad): string =>
  typeof p === 'number' && p !== 0 ? p + 'px' : `${p}`

export const padding = (
  top: pad,
  right: pad,
  bottom?: pad,
  left?: pad
): string => {
  let padding = `${process(top)} ${process(right)}`
  if (bottom) {
    padding += ' ' + process(bottom)
  }
  if (left) {
    padding += ' ' + process(left)
  }
  return padding
}

export const margin = padding
