import {
  getActionName,
  GetActionPayload,
  mergeActionName,
  MergeActionPayload,
  removeActionName,
  RemoveActionPayload,
  setActionName,
  SetActionPayload,
  updateActionName,
  UpdateActionPayload
} from 'common/utils/actions/crudActionNameMapper'
import { IActionCreators } from 'common/types/IActionCreator'
import { ActionCreator } from 'common/utils/actions/ActionCreator'
import { ObservableActionCreator } from 'common/utils/actions/ObservableActionCreator'
import {
  IGetActionCreator,
  IMergeActionCreator,
  IRemoveActionCreator,
  ISetActionCreator,
  IUpdateActionCreator
} from 'common/types/ActionCreators'
import { GetAction } from 'common/types/Actions'
import { RecordOf } from 'immutable'

export interface ICrudActionCreators<T> {
  set: ISetActionCreator<T>
  merge: IMergeActionCreator<T>
  remove: IRemoveActionCreator
  update: IUpdateActionCreator<T>
  get: IGetActionCreator<RecordOf<T>>
}

export const buildCrudActions = <T, A extends IActionCreators = {}>(
  storeName: string,
  actionsCreators: A = {} as A
): ICrudActionCreators<T> & A =>
  Object.assign<ICrudActionCreators<T>, A>(
    {
      set: new ActionCreator<SetActionPayload<T>>(setActionName(storeName)),
      merge: new ActionCreator<MergeActionPayload<T>>(
        mergeActionName(storeName)
      ),
      remove: new ActionCreator<RemoveActionPayload>(
        removeActionName(storeName)
      ),
      update: new ActionCreator<UpdateActionPayload<T>>(
        updateActionName(storeName)
      ),
      get: new ObservableActionCreator<
        GetActionPayload,
        GetAction<RecordOf<T>>
      >(getActionName(storeName))
    },
    actionsCreators
  )
