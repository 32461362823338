import React, { PureComponent } from 'react'
import Search from 'common/components/Search/Search'
import { searchAction } from 'App/actions/searchAction'
import { connect } from 'react-redux'
import { Subscription } from 'rxjs'
import { ISearchResults } from 'App/types/search'
import debounce from 'lodash.debounce'
import { compose } from 'recompose'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './SearchContainer.styles'
import Paper from '@material-ui/core/Paper/Paper'
import SearchResult from 'App/components/SearchResult/SearchResult'
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import NoResultIcon from 'App/assets/no-result.svg'
import SearchErrorIcon from 'App/assets/search-error.svg'
import Collapse from '@material-ui/core/Collapse/Collapse'
import { FormattedMessage } from 'react-intl'

interface ISearchContainerInputProps {
  className?: string
}

interface ISearchContainerProps
  extends IDispatchProps,
    WithStyles,
    ISearchContainerInputProps {}

interface ISearchContainerState {
  results?: ISearchResults
  focused: boolean
  loading: boolean
  noResult: string
  error: string
}

class SearchContainer extends PureComponent<
  ISearchContainerProps,
  ISearchContainerState
> {
  static renderNoResult(search: string, className: string) {
    return (
      <ListItem>
        <img className={className} src={NoResultIcon} />
        <span>
          <FormattedMessage id="App.containers.SearchContainer.noResult" />
          <b> {search}</b>
        </span>
      </ListItem>
    )
  }

  subscription?: Subscription
  state: ISearchContainerState = {
    results: undefined,
    focused: false,
    loading: false,
    error: '',
    noResult: ''
  }
  focus = () => this.setState({ focused: true })
  unFocus = () => this.setState({ focused: false })

  search = (input: string) => {
    this.setState({
      loading: true
    })
    const { observable } = this.props.searchAction({
      keyword: input
    })
    this.subscription && this.subscription.unsubscribe()
    this.subscription = observable.subscribe(
      results =>
        this.setState({
          results,
          loading: false,
          error: '',
          noResult: results.size ? '' : input
        }),
      error =>
        this.setState({
          results: undefined,
          error: error,
          noResult: input
        })
    )
  }

  renderError = (noResult: string, className: string) => {
    const clickHandler = () => this.search(noResult)
    return (
      <ListItem button={true} onClick={clickHandler}>
        <img className={className} src={SearchErrorIcon} />
        <span>
          <FormattedMessage id="App.containers.SearchContainer.wentWrong" />
        </span>
      </ListItem>
    )
  }

  debouncedSearch = debounce(this.search, 250)

  render() {
    const { classes, className } = this.props
    const { results, focused, noResult, error } = this.state
    return (
      <ClickAwayListener onClickAway={this.unFocus} mouseEvent="onMouseDown">
        <div className={className}>
          <Search onSearch={this.debouncedSearch} onClick={this.focus} />
          <Paper square={true} className={classes.results}>
            <Collapse in={focused && (!!results || !!error)} timeout={150}>
              <List className={classes.list}>
                {error
                  ? this.renderError(noResult, classes.icon)
                  : results && results.size
                  ? results.map((result, idx) => (
                      <SearchResult
                        key={idx}
                        onClick={this.unFocus}
                        result={result}
                      />
                    ))
                  : SearchContainer.renderNoResult(noResult, classes.icon)}
              </List>
            </Collapse>
          </Paper>
        </div>
      </ClickAwayListener>
    )
  }
}

interface IDispatchProps {
  searchAction: typeof searchAction.create
}

const mapDispatchToProps = { searchAction: searchAction.create }

export const Unconnected = SearchContainer
export default compose<ISearchContainerProps, ISearchContainerInputProps>(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(SearchContainer)
