import { BLOCK_STORE_NAME } from 'Blocks/constants/store'
import { blockFactory } from 'Blocks/redux/blockStore'
import { Entity } from 'common/utils/Entity'

export const BlockSchema = new Entity(
  BLOCK_STORE_NAME,
  {},
  {
    idAttribute: 'number',
    afterStrategy: blockFactory
  }
)
