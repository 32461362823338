import React, { PureComponent } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Search.styles'
import Icon from '@material-ui/core/Icon/Icon'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

interface ISearch extends WithStyles, WrappedComponentProps {
  onSearch(input: string): any
  onClick(): any
}

class Search extends PureComponent<ISearch> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value) {
      this.props.onSearch(value)
    }
  }

  render() {
    const { classes, onClick, intl } = this.props

    const placeHolderText = intl.formatMessage({
      id: 'common.components.Search.placeholder'
    })

    return (
      <Grid
        onClick={onClick}
        container={true}
        alignItems="center"
        justify="center"
        className={classes.search}
      >
        <Icon fontSize="inherit">search</Icon>
        <input
          className={classes.input}
          onChange={this.handleChange}
          type="text"
          placeholder={placeHolderText}
        />
      </Grid>
    )
  }
}

export default compose<ISearch, {}>(injectIntl, withStyles(styles))(Search)
