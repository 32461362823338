import { createEpicMiddleware, ofType } from 'redux-observable'
import Immutable from 'immutable'
import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  DeepPartial
} from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { BehaviorSubject } from 'rxjs'
import { mergeMap, takeUntil } from 'rxjs/operators'
import rootReducers, { IGlobalState } from './reducers'
import rootEpics from './epics'
import { isProduction } from 'common/utils'
import { IAction } from 'common/types/IAction'
export const configureStore = (
  initialState: {} | IGlobalState | DeepPartial<any>
): Store<IGlobalState> => {
  // Enhancer

  const epicMiddleware = createEpicMiddleware<IAction>()
  const middleWares = [epicMiddleware, thunk]

  let composeEnhancers = compose
  let epic$ = rootEpics
  let subject$: BehaviorSubject<any>
  if (!isProduction) {
    // adds logger
    middleWares.push(logger as any)

    // adds redux devtolls
    if (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
          immutable: Immutable
        }
      })
    }
    // adds hot reloading for epics
    subject$ = new BehaviorSubject(rootEpics)

    epic$ = (action$, ...rest) =>
      subject$.pipe(
        mergeMap(epic =>
          epic(action$, ...rest).pipe(
            takeUntil(action$.pipe(ofType('EPIC_END')))
          )
        )
      )
  }

  const enhancer = composeEnhancers(applyMiddleware(...middleWares))

  // Store
  const store = createStore(
    rootReducers,
    initialState as DeepPartial<any>,
    enhancer
  )

  // start epics
  epicMiddleware.run(epic$)

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducers = require('./reducers').default
      store.replaceReducer(nextReducers)
    })
    module.hot.accept('./epics', () => {
      const nextRootEpic = require('./epics').default
      // First kill any running epics
      store.dispatch({ type: 'EPIC_END' })
      // Now setup the new one
      subject$.next(nextRootEpic)
    })
  }
  return store
}
