import { schema } from 'normalizr'
import { AddressSchema } from 'Address/schemas/addressSchema'
import { BlockSchema } from 'Blocks/schemas/blockSchema'
import { TransactionSchema } from 'Transactions/schemas/transactionSchema'

export const SearchSchema = new schema.Array(
  {
    address: { data: AddressSchema },
    transaction: { data: TransactionSchema },
    block: { data: BlockSchema }
  },
  input => input.type
)
