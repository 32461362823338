import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { appUrls } from 'App/constants/urls'
import { IAction } from 'common/types/IAction'
import { normalize } from 'normalizr'
import { TransactionSchema } from 'Transactions/schemas/transactionSchema'
import { TransactionRecord } from 'Transactions/types/transactions'
import {
  transactionActions,
  transactionFactory
} from 'Transactions/redux/transactionStore'
import { List } from 'immutable'
import { EMPTY } from 'rxjs'
import { blockActions } from 'Blocks/redux/blockStore'
import { IBlockQueryTransactionAction } from 'Blocks/types/blocks'

export const fetchBlockTransaction: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, IBlockQueryTransactionAction>(
      blockActions.queryBlockTransactions.type
    ),
    switchMap(({ payload, observable }) =>
      ajax({
        url: appUrls.TOKEN_MANAGER,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          'jsonrpc': '2.0',
          'id': 1,
          'method': 'tokenManager.TransactionsByBlockNumber',
          'params': [{
              'blockNumber': payload.blockNumber,
              'limit': payload.limit,
              'p': Number(payload.p),
          }],
        },
      }).pipe(
        map(({ response }) => ({
          normalized: normalize(response.result || [], [TransactionSchema]),
        })),
        tap(
          ({
            normalized: {
              entities: { Transactions },
              result
            },
          }) => {
            const transactions = List<TransactionRecord>(
              result.map((id: string) => transactionFactory(Transactions[id]))
            )
            observable.next({ transactions })
          }
        ),
        map(({ normalized: { entities: { Transactions } } }) =>
          transactionActions.merge.create({
            data: Transactions
          })
        ),
        catchError(error => {
          observable.error(error.message)
          return EMPTY
        })
      )
    )
  )
