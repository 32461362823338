import getLocale from '../../i18n/getLocale'
import { SET_LANGUAGE } from '../constants/types'
import { IAction } from './actionType'

const initialState = {
  language: getLocale()
}

export default function(state = initialState, action: IAction) {
  const { type, payload } = action

  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload
      }
    default:
      return state
  }
}
