import { BLOCK_STORE_NAME } from 'Blocks/constants/store'
import { ObservableActionCreator } from 'common/utils/actions/ObservableActionCreator'
import {
  IBlockQueryAction,
  IBlockQueryTransactionAction,
  IBlockTransactionQueryPayload,
  IQueryPayload
} from 'Blocks/types/blocks'

export const customBlockActions = {
  query: new ObservableActionCreator<IQueryPayload, IBlockQueryAction>(
    `${BLOCK_STORE_NAME}/query`
  ),
  queryBlockTransactions: new ObservableActionCreator<
    IBlockTransactionQueryPayload,
    IBlockQueryTransactionAction
  >(`${BLOCK_STORE_NAME}/queryBlockTransactions`)
}
