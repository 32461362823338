import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { transactionActions } from 'Transactions/redux/transactionStore'
import { ITransactionGetAction } from 'Transactions/types/transactions'
import { IAction } from 'common/types/IAction'
import { normalize } from 'normalizr'
import { TransactionSchema } from 'Transactions/schemas/transactionSchema'
import { EMPTY } from 'rxjs'
import { appUrls } from 'App/constants/urls'

export const fetchTransaction: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, ITransactionGetAction>(transactionActions.get.type),
    switchMap(({ payload, observable }) =>
    ajax({
      url: appUrls.TOKEN_MANAGER,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        "jsonrpc":"2.0",
        "method":"tokenManager.Transaction",
        "params":[payload.key],
        "id":1,
      }
    }).pipe(
        map(({ response: {result} }) => {
          return normalize(result, TransactionSchema);
        }),
        tap(({ entities: { Transactions }, result }) => {
          observable.next(Transactions[result])
          observable.complete()
        }),
        map(({ entities: { Transactions }, result }) =>
          transactionActions.set.create({
            data: Transactions[result],
            key: result
          })
        ),
        catchError(error => {
          observable.error(error.message)
          return EMPTY
        })
      )
    )
  )
