import * as React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Menu from '@material-ui/icons/Menu'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Logo from 'App/assets/ThunderLogo.svg'
import ThunderName from 'App/assets/ThunderName.svg'
import ThunderCoreScan from 'App/assets/ThunderCoreScan.svg'
import styles from './Header.styles'
import { Routes } from 'App/constants/Routes'
import { ButtonWithTracking, NavLinkWithTracking } from 'tracking/components'
import { NavLink, withRouter } from 'react-router-dom'
import { Hidden } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MobileMenu from 'App/components/MobileMenu/MobileMenu'
import { compose } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { SET_LANGUAGE } from '../../constants/types'
import { IGlobalState } from 'configuration/reducers'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button/Button'

interface IHeaderProps
  extends IHeaderOwnProps,
    RouteComponentProps<{ id: string }>,
    WithStyles,
    IDispatchProps,
    IMapStateProps {}

interface IHeaderOwnProps {}
interface IHeaderState {
  open: boolean
  language: string
  selectOpen: boolean
}

class Header extends React.PureComponent<IHeaderProps, IHeaderState> {
  state = {
    open: false,
    language: this.props.language,
    selectOpen: false,
  }
  toggle = () => this.setState({ open: !this.state.open })

  handleChange = (event?: React.ChangeEvent<{ value?: unknown }>) => {
    const { appActions } = this.props
    if (event && event.target.value) {
      appActions.selectLanguage(event.target.value)
      this.setState({ language: event.target.value as string })
    }
  }

  public render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <>
        {open && <MobileMenu toggle={this.toggle} />}
        <Grid
          className={classes.header}
          alignItems="center"
          justify="space-between"
          container={true}
        >
          <NavLinkWithTracking
            to="/"
            label="Thunder Logo"
            className={classes.link}
          >
            <Hidden smDown>
              <img
                src={ThunderName}
                className={classes.logoName}
                alt="Thunder Name"
              />
            </Hidden>
            <img
              src={ThunderCoreScan}
              className={classes.logoTitle}
              alt="ThunderCore scan"
            />
          </NavLinkWithTracking>
          <Grid item={true}>
            <Hidden mdUp>
              <IconButton onClick={this.toggle} className={classes.icon}>
                <Menu color="inherit" />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              <Grid container={true} alignItems="center" justify="center">
                <ButtonWithTracking
                  id="Dev Portal"
                  label="Dev Portal"
                  href="https://docs.developers.thundercore.com"
                >
                  <FormattedMessage id="App.components.Header.devPortal" />
                </ButtonWithTracking>
                <ButtonWithTracking
                  id="Thundercore"
                  label="Thundercore"
                  href="https://thundercore.com"
                >
                  <FormattedMessage id="App.components.Header.thundercore" />
                </ButtonWithTracking>
                <ButtonWithTracking
                  label="All Blocks"
                  component={NavLink}
                  id={Routes.Blocks.name}
                  to={Routes.Blocks.getPath()}
                >
                  <FormattedMessage id="App.components.Header.blocks" />
                </ButtonWithTracking>
                <ButtonWithTracking
                  label="All Transactions"
                  component={NavLink}
                  id={Routes.Transactions.name}
                  to={Routes.Transactions.getPath()}
                >
                  <FormattedMessage id="App.components.Header.transactions" />
                </ButtonWithTracking>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState((prevState) => ({
                      selectOpen: !prevState.selectOpen,
                    }))
                  }}
                >
                  <Select
                    value={this.state.language}
                    open={this.state.selectOpen}
                    onOpen={() => {}}
                    onClose={this.handleChange}
                    onChange={this.handleChange}
                    displayEmpty
                    disableUnderline
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    className={classes.select}
                  >
                    <MenuItem value={'en'} className={classes.menuItem}>
                      ENGLISH
                    </MenuItem>
                    <MenuItem value={'zh-Hant'} className={classes.menuItem}>
                      繁體中文
                    </MenuItem>
                    <MenuItem value={'zh-Hans'} className={classes.menuItem}>
                      简体中文
                    </MenuItem>
                  </Select>
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </>
    )
  }
}

interface IMapStateProps {
  language: string
}

export const mapStateToProps = (state: IGlobalState): IMapStateProps => {
  return {
    language: state.app.language,
  }
}
interface IDispatchProps {
  appActions: {
    selectLanguage: Function
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  appActions: {
    selectLanguage: (lang: string) =>
      dispatch({ type: SET_LANGUAGE, payload: lang }),
  },
})

export const Unconnected = Header

export default compose<IHeaderProps, IHeaderOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(Header)
