import { IReducers } from 'common/types/IReducer'
import { IAction } from 'common/types/IAction'
import { Record, RecordOf } from 'immutable'
import Factory = Record.Factory
import { IDataState } from 'common/types/IDataState'
import {
  mergeActionName,
  MergeActionPayload,
  removeActionName,
  RemoveActionPayload,
  setActionName,
  SetActionPayload,
  updateActionName,
  UpdateActionPayload
} from 'common/utils/actions/crudActionNameMapper'
import { Reducer } from 'redux'

const setActionHandler = <T>(
  state: IDataState<T>,
  action: IAction<SetActionPayload<T>>,
  factory: Factory<T>
): IDataState<T> => {
  const { key, data } = action.payload
  // convert data to a record
  const record = factory(data)
  return state.set(key, record)
}

const mergeActionHandler = <T>(
  state: IDataState<T>,
  action: IAction<MergeActionPayload<T>>,
  factory: Factory<T>
): IDataState<T> => {
  // converts the payload to records
  const convertedPayload: { [key: string]: RecordOf<T> } = {}
  const data = action.payload.data
  for (let key in data) {
    const currentRecord = state.get(key)
    if (!currentRecord) {
      convertedPayload[key] = factory(data[key])
    } else {
      convertedPayload[key] = currentRecord.merge(data[key])
    }
  }
  return state.merge(convertedPayload)
}

const removeActionHandler = <T>(
  state: IDataState<T>,
  action: IAction<RemoveActionPayload>
): IDataState<T> => {
  const { key } = action.payload
  return state.remove(key)
}

const updateActionHandler = <T>(
  state: IDataState<T>,
  action: IAction<UpdateActionPayload<T>>
): IDataState<T> => {
  const { key, data } = action.payload
  // if object does not exist, do not update
  return state.get(key) ? state.mergeIn([key], data) : state
}

export const buildCrudDataReducers = <T, R extends IReducers<T> = {}>(
  initialState: IDataState<T>,
  storeName: string,
  recordFactory: Factory<T>,
  reducerOverrides: R = {} as R
): Reducer<IDataState<T>, IAction> => {
  let reducers = Object.assign(
    {
      [setActionName(storeName)]: setActionHandler,
      [mergeActionName(storeName)]: mergeActionHandler,
      [removeActionName(storeName)]: removeActionHandler,
      [updateActionName(storeName)]: updateActionHandler
    },
    reducerOverrides
  )

  return (state = initialState, action: IAction): IDataState<T> => {
    const handler = reducers[action.type]
    return handler ? handler(state, action, recordFactory) : state
  }
}
