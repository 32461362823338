import * as React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { compose } from 'recompose'
import {
  Route as ReactRoute,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom'
import Header from '../Header/Header'
import SearchContainer from 'App/containers/SearchContainer/SearchContainer'
import { Route } from 'App/utils/Route'
import styles from './App.styles'
import { RouteComponents } from 'App/constants/RouteComponents'
import ScrollRestore from 'common/components/ScrollRestore/ScrollRestore'
import { track } from 'tracking/utils/track'
import { GAPageViewEvent } from 'tracking/events'

type IAppProps = RouteComponentProps & WithStyles

interface IAppState {
  activeRoute: Route
}

const getActiveRoute = (props: IAppProps): Route => {
  const key = Object.keys(RouteComponents).find(key =>
    (RouteComponents as any)[key].route.isRoute(props.location.pathname)
  ) as string
  return (RouteComponents as any)[key].route
}

class App extends React.PureComponent<IAppProps, IAppState> {
  state = {
    activeRoute: getActiveRoute(this.props)
  }

  static getDerivedStateFromProps(props: IAppProps) {
    return {
      activeRoute: getActiveRoute(props)
    }
  }

  componentDidMount(): void {
    track(
      new GAPageViewEvent(
        this.state.activeRoute.name,
        this.props.location.pathname + this.props.location.search
      )
    )
  }

  componentDidUpdate({ location: { pathname, search } }: IAppProps): void {
    if (
      this.props.location.pathname !== pathname ||
      this.props.location.search !== search
    )
      track(
        new GAPageViewEvent(
          this.state.activeRoute.name,
          this.props.location.pathname + this.props.location.search
        )
      )
    // this.state.activeRoute
  }

  static renderRoute(key: string): React.ReactNode {
    const {
      route: { exact, path },
      component
    } = (RouteComponents as any)[key]
    return (
      <ReactRoute key={key} exact={exact} component={component} path={path} />
    )
  }

  public render() {
    const { classes } = this.props
    const { activeRoute } = this.state
    return (
      <ScrollRestore>
        <div className={classes.app}>
          <div className={classes.headerContainer}>
            <Header />
          </div>
          {activeRoute.showSearch && (
            <SearchContainer className={classes.searchContainer} />
          )}
          <div className={classes.routeContainer}>
            <Switch>{Object.keys(RouteComponents).map(App.renderRoute)}</Switch>
          </div>
        </div>
      </ScrollRestore>
    )
  }
}

export const Unconnected = App

export default compose<IAppProps, IAppProps>(
  withRouter,
  withStyles(styles)
)(App)
