import NotFoundPageLoader from '../components/Loader/Loader'
import NotFound from 'App/components/NotFound/NotFound'
import Loadable from 'react-loadable'
import { Routes } from './Routes'
const TransactionPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TransactionPage" */ 'Transactions/containers/TransactionPageContainer/TransactionPageContainer'),
  loading: NotFoundPageLoader
})

const HomeComponent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomePage" */ 'Home/containers/HomePageContainer/HomePageContainer'),
  loading: NotFoundPageLoader
})
const BlockPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "BlockPage" */ 'Blocks/containers/BlockPageContainer'),
  loading: NotFoundPageLoader
})

const BlocksPageContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Blocks" */ 'Blocks/containers/BlocksPageContainer/BlocksPageContainer'),
  loading: NotFoundPageLoader
})

const TransactionsComponent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Transactions" */ 'Transactions/containers/TransactionsPageContainer/TransactionsPageContainer'),
  loading: NotFoundPageLoader
})

const AddressComponent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Transactions" */ 'Address/containers/AddressPageContainer/AddressPageContainer'),
  loading: NotFoundPageLoader
})

export const RouteComponents = {
  Home: {
    component: HomeComponent,
    route: Routes.Home
  },
  Transactions: {
    component: TransactionsComponent,
    route: Routes.Transactions
  },
  Transaction: {
    component: TransactionPage,
    route: Routes.Transaction
  },
  Blocks: {
    component: BlocksPageContainer,
    route: Routes.Blocks
  },
  Block: {
    component: BlockPage,
    route: Routes.Block
  },
  Address: {
    component: AddressComponent,
    route: Routes.AddressInfo
  },
  NotFoundPage: {
    component: NotFound,
    route: Routes.NotFoundPage
  }
}
