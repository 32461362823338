import * as React from 'react'
import { GAEvent } from 'tracking/events/GAEvent'
import { track } from 'tracking/utils/track'

const functionNameMapper = (key: string) => `__tracking__${key}`

export function withTracking<D, B extends keyof D, C>(
  DecoratedComponent: React.ComponentType<D>,
  trackingKey: B,
  trackingMapper: (props: C) => GAEvent | undefined
) {
  return class WithTracking extends React.PureComponent<D & C> {
    constructor(props: any) {
      super(props)
      // @ts-ignore
      this[functionNameMapper(trackingKey)] = (...args: any) => {
        const event = trackingMapper(this.props as C & D)
        if (event) {
          track(event)
        }

        const prop = this.props[trackingKey]
        if (prop && typeof prop === 'function') {
          prop(...args)
        }
      }
    }

    render() {
      const props = {
        // @ts-ignore
        ...this.props,
        // @ts-ignore
        [trackingKey]: this[functionNameMapper(trackingKey)]
      }
      return <DecoratedComponent {...props} />
    }
  }
}
