import { blockActions } from 'Blocks/redux/blockStore'
import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap, delay } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { appUrls } from 'App/constants/urls'
import { EMPTY, Observable, of } from 'rxjs'
import { transactionActions } from 'Transactions/redux/transactionStore'
import { IAction } from 'common/types/IAction'
import { normalize } from 'normalizr'
import { ISearchAction, ISearchResult } from 'App/types/search'
import { searchAction } from 'App/actions/searchAction'
import { SearchSchema } from 'App/schemas/searchSchema'
import { List } from 'immutable'
import { BLOCK_STORE_NAME } from 'Blocks/constants/store'
import { ADDRESS_STORE_NAME } from 'Address/constants/store'
import { TRANSACTION_STORE_NAME } from 'Transactions/constants/store'
import { isAddress } from 'web3-utils'

const factoryMapper = {
  block: BLOCK_STORE_NAME,
  address: ADDRESS_STORE_NAME,
  transaction: TRANSACTION_STORE_NAME
}

type ApiResult = {
  id: {
    type: 'block' | 'address' | 'transaction'
    data: number | string
  }
}

function getAddress(address) {
  if (address.length !== 42) {
    address = "0x" + address
  }
  return ajax({
    url: appUrls.RPC_URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      'jsonrpc': '2.0',
      'id': 1,
      'method': 'eth_getBalance',
      'params': [address, 'latest'],
    }
  }).pipe(
    map(response => {
      return {
        results: [{
          type: 'address',
          data: {
            hash: address,
            balance: response.response.result
          }
        }]
      }
    }),
    catchError(() => of({results: []})),
  )
}

const isTransaction = (str: string) => /^0x[0-9a-f]{64}$/i.test(str)

function getTransaction(hash) {
  return ajax({
    url: appUrls.TOKEN_MANAGER,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      "jsonrpc":"2.0",
      "method":"tokenManager.Transaction",
      "params":[hash],
      "id":1,
    }
  }).pipe(
    map(({response: { result }}) => {
      const r = { results: [] }
      if (result) {
        r.results.push({
          type: 'transaction',
          data: result
        })
      }
      return r
    }),
    catchError(() => of({ results: []}))
  )
}


function getBlock(number) {
  return ajax({
    url: appUrls.TOKEN_MANAGER,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      'jsonrpc': '2.0',
      'id': 1,
      'method': 'tokenManager.Block',
      'params': [number],
    }
  }).pipe(
    map(({response: { result }}) => {
      return {
        results: [{
          type: 'block',
          data: result[0],
        }]
      }
    }),
    catchError(() => of({ results: []}))
  )
}


// TODO: refactor this as a service
function search(keyword): Observable<any> {
  if (isAddress(keyword)) {
    return getAddress(keyword)
  } else if (isTransaction(keyword)) {
    return getTransaction(keyword)
  } else if (!keyword.match(/^0x/) && !isNaN(keyword)) {
    const number = parseInt(keyword, 10)
    return getBlock(number)
  }
  return of({results: []}).pipe(delay(10))
}

export const searchEpic: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, ISearchAction>(searchAction.type),
    switchMap(({ payload, observable }) =>
      search(payload.keyword).pipe(
        map(({ results }) => normalize(results, SearchSchema)),
        tap(({ entities, result }) => {
          const results = List<ISearchResult>(
            result.map(({ id: { type, data } }: ApiResult) => {
              const record = entities[factoryMapper[type]][data]
              return {
                type,
                data: record
              }
            })
          )
          observable.next(results)
          observable.complete()
        }),
        switchMap(({ entities: { Transactions, Blocks, Address } }) => {
          const actions = []
          if (Transactions) {
            actions.push(
              transactionActions.merge.create({
                data: Transactions
              })
            )
          }
          if (Blocks) {
            actions.push(
              blockActions.merge.create({
                data: Blocks
              })
            )
          }
          return actions.length ? of(...actions) : EMPTY
        }),
        catchError(error => {
          observable.error(error.message)
          return EMPTY
        })
      )
    )
  )
