import { PureComponent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

class ScrollRestore extends PureComponent<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    const shouldHold =
      this.props.location.state && this.props.location.state.holdPosition
    if (this.props.location !== prevProps.location && !shouldHold) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollRestore)
