import { GAEvent } from 'tracking/events/GAEvent'
import { GAPageViewEvent } from 'tracking/events'

function isPageView(
  event: GAEvent | GAPageViewEvent
): event is GAPageViewEvent {
  return !!(event as GAPageViewEvent).page
}

export const track = (event: GAEvent | GAPageViewEvent) => {
  if (isPageView(event)) {
    ga('send', {
      hitType: 'pageview',
      ...event
    })
  } else {
    ga('send', 'event', event.category, event.action, event.label)
  }
}
