import createStyles from '@material-ui/core/styles/createStyles'

export default () =>
  createStyles({
    logo: {
      width: 28,
      height: 28,
    },
    icon: {
      color: '#fff',
      marginRight: -12,
    },
    logoName: {
      paddingRight: 4,
      borderRight: '2px solid #fff',
    },
    logoTitle: {
      marginTop: -6,
      marginLeft: 8,
      height: 24,
    },
    link: {
      fontFamily: 'Courier',
      fontWeight: 700,
      textDecoration: 'none',
      fontSize: 22,
      display: 'flex',
      alignItems: 'center',
    },
    header: {
      height: '100%',
      '& div div a:hover': {
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '8px',
      },
      '& button svg': {
        color: '#fff',
      },
    },
    select: {
      fontSize: 13,
    },
    menuItem: {
      backgroundColor: 'rgb(219, 219, 219)',
      fontSize: 13,
    },
  })
