import { Map, List } from 'immutable'
import { createSelector } from 'reselect'
import { IDataSelectors } from 'common/types/IDataSelectors'

export const createSelectors = <T>(): IDataSelectors<T> => {
  return {
    get: (store, id) => store.get(id),
    // using reselect. if you components us this at the same time, this will not cache properly. use re-reselect in that case
    // it is possible to pass in ids and get gaps in the result
    getList: createSelector(
      (store: Map<string, T>) => store,
      (_: Map<string, T>, ids?: string[]) => ids,
      (store, ids?): List<T> | undefined =>
        ids && (List(ids.map(id => store.get(id))) as List<T>)
    )
  }
}
