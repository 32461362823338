import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'
import { padding } from 'common/utils/styles'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    input: {
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      height: '100%',
      flex: 1,
      padding: padding(0, spacing.unit),
      fontSize: 18
    },
    search: {
      fontSize: 32,
      height: '100%'
    },
    [breakpoints.down('sm')]: {
      input: {
        fontSize: 14
      },
      search: {
        fontSize: 24
      }
    }
  })
