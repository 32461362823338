import { Subject } from 'rxjs'
import { IObservableAction } from 'common/types/IObersvableAction'
import {
  ICreateObservableAction,
  IObservableActionCreator
} from 'common/types/IObservableActionCreator'

export class ObservableActionCreator<T, R extends IObservableAction<T>>
  implements IObservableActionCreator<T, R> {
  type: string
  create: ICreateObservableAction<T, R>

  constructor(type: string) {
    this.type = type
    this.create = this.buildActionCreator(type)
  }

  buildActionCreator = (type: string) => (payload: T): R => {
    return {
      type: type,
      payload,
      observable: new Subject()
    } as R
  }
}
