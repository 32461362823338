import { combineReducers } from 'redux'
import { blockReducer } from 'Blocks/redux/blockStore'
import { transactionReducer } from 'Transactions/redux/transactionStore'
import { addressReducer } from 'Address/redux/addressStore'
import app from 'App/redux/appReducer'

const rootReducer = combineReducers({
  blocks: blockReducer,
  transactions: transactionReducer,
  address: addressReducer,
  app: app
})

export default rootReducer

export type IGlobalState = ReturnType<typeof rootReducer>
