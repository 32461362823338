import { DataStore } from 'common/utils/store/DataStore'
import { BLOCK_STORE_NAME } from 'Blocks/constants/store'
import { customBlockActions } from 'Blocks/redux/customBlockActions'
import { Record } from 'immutable'
import { BlockType } from 'Blocks/types/blocks'

const BlockRecordFactory = Record<BlockType>({
  number: 0,
  hash: '0x0',
  timestamp: Date.now(),
  transactionCount: 0,
  parentHash: '',
  size: '',
  nonce: 1,
  gasLimit: '',
  gasUsed: '',
  extraData: '',
  miner: ''
})

export const blockStore = new DataStore<BlockType, typeof customBlockActions>(
  BLOCK_STORE_NAME,
  BlockRecordFactory,
  {
    actionCreators: customBlockActions
  }
)

export const blockReducer = blockStore.reducer
export const blockFactory = blockStore.RecordFactory

export const blockActions = blockStore.actions
export const blockSelectors = blockStore.selectors
