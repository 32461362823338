import * as React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './MobileMenu.styles'
import { Routes } from 'App/constants/Routes'
import { ListItemWithTracking } from 'tracking/components'
import { NavLink } from 'react-router-dom'
import { List } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseButton from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import { SET_LANGUAGE } from '../../constants/types'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { compose } from 'recompose'

interface IMobileMenuProps
  extends IMobileMenuOwnProps,
    IDispatchProps,
    WithStyles {}

interface IMobileMenuOwnProps {
  toggle(): any
}

class MobileMenu extends React.PureComponent<IMobileMenuProps> {
  selectLang = (lang: string): void => {
    const { selectLanguage } = this.props.appActions
    selectLanguage(lang)
  }

  public render() {
    const { classes, toggle } = this.props
    return (
      <div className={classes.full}>
        <Grid container justify="flex-end">
          <IconButton onClick={toggle} className={classes.icon}>
            <CloseButton />
          </IconButton>
        </Grid>
        <List onClick={toggle} className={classes.list}>
          <ListItemWithTracking
            className={classes.item}
            button
            component="a"
            id="Thundercore"
            label="Thundercore"
            href="https://thundercore.com"
          >
            <FormattedMessage id="App.components.MobileMenu.thundercore" />
          </ListItemWithTracking>
          <ListItemWithTracking
            button
            component="a"
            className={classes.item}
            id="Dev Portal"
            label="Dev Portal"
            href="https://docs.developers.thundercore.com"
          >
            <FormattedMessage id="App.components.MobileMenu.devPortal" />
          </ListItemWithTracking>
          <ListItemWithTracking
            button
            className={classes.item}
            label="All Blocks"
            component={NavLink}
            id={Routes.Blocks.name}
            to={Routes.Blocks.getPath()}
          >
            <FormattedMessage id="App.components.MobileMenu.blocks" />
          </ListItemWithTracking>
          <ListItemWithTracking
            button
            className={classes.item}
            label="All Transactions"
            component={NavLink}
            id={Routes.Transactions.name}
            to={Routes.Transactions.getPath()}
          >
            <FormattedMessage id="App.components.MobileMenu.transactions" />
          </ListItemWithTracking>
          <div className={classes.languageContainer}>
            <div onClick={() => this.selectLang('en')}>ENGLISH</div>
            <div>|</div>
            <div onClick={() => this.selectLang('zh-Hant')}>繁體中文</div>
            <div>|</div>
            <div onClick={() => this.selectLang('zh-Hans')}>简体中文</div>
          </div>
        </List>
      </div>
    )
  }
}

interface IDispatchProps {
  appActions: {
    selectLanguage: Function
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  appActions: {
    selectLanguage: (lang: string) =>
      dispatch({ type: SET_LANGUAGE, payload: lang })
  }
})

export const Unconnected = MobileMenu

export default compose<IMobileMenuProps, IMobileMenuOwnProps>(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(MobileMenu)
