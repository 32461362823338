import * as React from 'react'
import NotFoundIcon from 'App/assets/not-found.svg'
import { WithStyles } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './NotFound.styles'
import Typography from '@material-ui/core/Typography/Typography'
import { Routes } from 'App/constants/Routes'
import { NavLinkWithTracking } from 'tracking/components'
import { FormattedMessage } from 'react-intl'

class NotFound extends React.PureComponent<WithStyles> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <img className={classes.image} alt="not-found-img" src={NotFoundIcon} />
        <Typography variant="display1" className={classes.title}>
          <FormattedMessage id="App.components.NotFound.notFound" />
        </Typography>
        <Typography variant="subheading">
          <FormattedMessage id="App.components.NotFound.canNotAccess" />
        </Typography>
        <Typography variant="subheading">
          <FormattedMessage id="App.components.NotFound.urlHomeReport1" />
          <NavLinkWithTracking label="Not Found" to={Routes.Home.getPath()}>
            <FormattedMessage id="App.components.NotFound.urlHomeReport2" />
          </NavLinkWithTracking>
          <FormattedMessage id="App.components.NotFound.urlHomeReport3" />
        </Typography>
      </div>
    )
  }
}

export const Unconnected = NotFound

export default withStyles(styles)(NotFound)
