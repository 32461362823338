import { schema } from 'normalizr'

interface EntityOptions extends schema.EntityOptions {
  afterStrategy?: schema.StrategyFunction
}
export class Entity extends schema.Entity {
  _afterStrategy = (input: any) => ({ ...input })

  constructor(key: string, definition = {}, options: EntityOptions = {}) {
    super(key, definition, options)
    this._afterStrategy = this._afterStrategy || options.afterStrategy
  }

  // @ts-ignore
  normalize(input, parent, key, visit, addEntity) {
    // @ts-ignore
    const processedEntity = this._processStrategy(input, parent, key)
    // @ts-ignore
    Object.keys(this.schema).forEach(key => {
      if (
        processedEntity.hasOwnProperty(key) &&
        typeof processedEntity[key] === 'object'
      ) {
        // @ts-ignore
        const schema = this.schema[key]
        processedEntity[key] = visit(
          processedEntity[key],
          processedEntity,
          key,
          schema,
          addEntity
        )
      }
    })
    addEntity(this, this._afterStrategy(processedEntity), input, parent, key)
    // @ts-ignore
    return this.getId(input, parent, key)
  }
}
