import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'
import { padding } from 'common/utils/styles'

export default ({ palette, spacing, breakpoints }: Theme) => {
  const lgPad = padding(0, spacing.unit * 4)
  const smPad = padding(0, spacing.unit * 2)
  return createStyles({
    '@global': {
      html: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        height: '100%',
      },
      body: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      a: {
        color: palette.secondary.main,
      },
    },
    app: {
      minHeight: '100%',
      overflow: 'hidden',
    },
    headerContainer: {
      position: 'relative',
      zIndex: 5,
      backgroundColor: palette.primary.main,
      height: 60,
      padding: lgPad,
      '& span': {
        color: '#fff',
      },
    },
    searchContainer: {
      position: 'relative',
      backgroundColor: 'white',
      height: 60,
      padding: lgPad,
    },
    routeContainer: {
      position: 'relative',
      zIndex: 2,
      margin: 'auto',
      maxWidth: 1200,
      padding: lgPad,
    },
    [breakpoints.down('xs')]: {
      routeContainer: {
        padding: 0,
      },
      searchContainer: {
        padding: smPad,
      },
      headerContainer: {
        padding: smPad,
      },
    },
  })
}
