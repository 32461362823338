import { Epic, ofType } from 'redux-observable'
import { catchError, map, switchMap, tap, partition } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import {
  transactionActions,
  transactionFactory
} from 'Transactions/redux/transactionStore'
import { List } from 'immutable'
import { IAction } from 'common/types/IAction'
import {
  ITransactionQueryAction,
  TransactionRecord
} from 'Transactions/types/transactions'
import { urls } from 'Transactions/constants/urls'
import { normalize } from 'normalizr'
import { TransactionSchema } from 'Transactions/schemas/transactionSchema'
import { EMPTY } from 'rxjs'
import { appUrls } from 'App/constants/urls'
import { parse } from 'qs'

export const queryTransactionEpic: Epic<IAction> = action$ =>
  action$.pipe(
    ofType<IAction, ITransactionQueryAction>(transactionActions.query.type),
    switchMap(({ payload, observable }) =>
    ajax({
      url: appUrls.TOKEN_MANAGER,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        "jsonrpc":"2.0",
        "method":"tokenManager.Transactions",
        "params":[{
          "pageSize": 25,
          "page": parseInt(payload.p as string) || 1,
          "tid": (payload.tid as string) ? parseInt(payload.tid) : (payload.tid || 0),
        }],
        "id":1,
      }
    }).pipe(
      map(({ response }) => {
        const hasMore = response.result.length > 0 && response.result[response.result.length-1].hasMore
        return ({
        normalized: normalize(response.result, [TransactionSchema]),
        hasMore: hasMore,
      })}),
      tap(
          ({
            normalized: {
              entities: { Transactions },
              result
            },
            hasMore
          }) => {
            const transactions = List<TransactionRecord>(
              result.map((id: string) => transactionFactory(Transactions[id]))
            )
            observable.next({ transactions, hasMore })
          }
        ),
        map(({ normalized: { entities: { Transactions } } }) =>
          transactionActions.merge.create({
            data: Transactions
          })
        ),
        catchError(error => {
          observable.error(error.message)
          return EMPTY
        })
      )
    )
  )
