import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

export default createMuiTheme({
  palette: {
    background: {
      default: '#edeceb',
    },
    primary: {
      main: '#0844D2',
    },
    secondary: {
      main: '#0844D2',
    },
  },
  typography: {
    display2: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        tableLayout: 'fixed',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
      },
    },
    MuiSelect: {
      select: {
        paddingRight: 25,
        '&:focus': {
          background: 'inherit',
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&&:hover': {
          backgroundColor: 'rgb(219, 219, 219)',
          color: 'white',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiInput: {
      input: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: 'rgba(239, 247, 255, 1)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'rgba(239, 247, 255, 1)',
          },
          '& span div': {
            color: '#0844D2',
          },
        },
        '& span div': {
          color: '#fff',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'rgb(219,219,219)',
        },
      },
    },
  },
  props: {
    MuiTable: {
      padding: 'dense',
    },
  },
})
