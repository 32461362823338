import { combineEpics } from 'redux-observable'
import * as appEpics from 'App/epics'
import * as blockEpics from 'Blocks/epics'
import * as transactionEpics from 'Transactions/epics'
import * as addressEpics from 'Address/epics'

export default combineEpics(
  ...Object.values(appEpics),
  ...Object.values(blockEpics),
  ...Object.values(transactionEpics),
  ...Object.values(addressEpics)
)
