import pathToRegexp, { compile, PathFunction } from 'path-to-regexp'
import { stringify } from 'qs'

type RouteOptions = {
  path: string
  name: string
  exact: boolean
  showSearch: boolean
}

export class Route {
  path: string
  name: string
  exact: boolean
  showSearch: boolean
  reg: RegExp
  compile: PathFunction

  constructor({ path, name, exact, showSearch }: RouteOptions) {
    this.path = path
    this.name = name
    this.showSearch = showSearch
    this.reg = pathToRegexp(path, undefined, { end: exact, sensitive: false })
    this.compile = compile(path)
    this.exact = exact
  }

  isRoute(path: string): boolean {
    return !!this.reg.exec(path)
  }

  getPath(params = {}, query?: {}) {
    let path = this.compile(params)
    if (query) {
      path += '?' + stringify(query)
    }
    return path
  }
}
