import React from 'react'
import { IntlProvider } from 'react-intl'
import { flatten } from './flattenMessages'
import en from '../translations/en.json'
import zh_Hant from '../translations/zh-Hant.json'
import zh_Hans from '../translations/zh-Hans.json'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { IGlobalState } from 'configuration/reducers'

const localeMessages: any = {
  en: flatten(en),
  'zh-Hant': flatten(zh_Hant),
  'zh-Hans': flatten(zh_Hans)
}

interface IIntlContainerProps extends IIntlContainerOwnProps, IMapStateProps {}

interface IIntlContainerOwnProps {}

interface IIntlContainerState {
  locale: string
}

class IntlContainer extends React.PureComponent<
  IIntlContainerProps,
  IIntlContainerState
> {
  render() {
    const { children, language } = this.props

    return (
      <IntlProvider
        key={language}
        locale={language}
        messages={
          localeMessages[language]
            ? localeMessages[language]
            : localeMessages['en']
        }
      >
        {children}
      </IntlProvider>
    )
  }
}

interface IMapStateProps {
  language: string
}

export const mapStateToProps = (state: IGlobalState): IMapStateProps => {
  return {
    language: state.app.language
  }
}

export const Unconnected = IntlContainer

export default compose<IIntlContainerProps, IIntlContainerOwnProps>(
  connect(mapStateToProps, {})
)(IntlContainer)
